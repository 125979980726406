import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../../components/Common/Layout";
import CompanyLogo from "../../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../../components/Website/ProjectsNavigation";
import ServicePellet from "../../../components/Website/ServicePellet";
import ProjectImagesSwiper from "../../../components/Website/ProjectImagesSwiper";

const Skippit = ({ data }) => {
  const {
    SkippitLiveVideoPt,
    SkippitMainNavigationPt,
    SkippitOnboardingPt,
    SkippitMatchesPt,
    SkippitProfilePt,
    SkippitHeaderPt,
  } = data;
  const [modalVisible, setModalVisible] = useState(false);

  function getImageData(data) {
    return data.edges.map((edge) => edge.node.childImageSharp.fluid);
  }

  const liveVideoImages = getImageData(SkippitLiveVideoPt);
  const mainAppImages = getImageData(SkippitMainNavigationPt);
  const onboardingImages = getImageData(SkippitOnboardingPt);
  const matchesImages = getImageData(SkippitMatchesPt);
  const profileImages = getImageData(SkippitProfilePt);
  const headerImage = getImageData(SkippitHeaderPt);

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="pt"
      title="Brocode Studio || Skippit"
      description=" Skippit é um novo tipo de primeiro encontro que usa vídeo
      para mudar a maneira como as pessoas usam e experimentam os
      aplicativos de namoro"
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={6} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Skippit App</h2>
                  </div>
                  <ServicePellet service="UI & UX" />
                  <ServicePellet service="Branding" />
                  <ServicePellet service="Development" />
                  <Row className="my-md-4 my-1 d-md-block d-none mx-0">
                    <p className="wow move-up  mw-100 mw-md-75 mb--20">
                      Um novo tipo de primeiro encontro{" "}
                      <span role="img">❤️</span>
                    </p>
                    <p className="wow move-up  mw-100 mw-md-75 mb--20">
                      Skippit é um novo tipo de primeiro encontro que usa vídeo
                      para mudar a maneira como as pessoas usam e experimentam
                      os aplicativos de namoro
                    </p>
                    <p className="wow move-up  mw-100 mw-md-75 mb--20">
                      Ligando para substituir os Ubers caros, os primeiros
                      drinques estranhos e a pesca do gato que são comuns na
                      maioria dos aplicativos de namoro.
                    </p>
                    <p className="wow move-up  mw-100 mw-md-75 mb--20">
                      Skippit foi lançado nos EUA. Na primeira semana de
                      lançamento atingiu o número 66 na App Store.
                    </p>
                  </Row>
                </div>
              </Fade>
            </Col>
            <Col md={6} xs={12}>
              <Img fluid={headerImage[0]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 d-md-none d-block mx-0">
              <p className="wow move-up  mw-100 mw-md-75 mb--20">
                Um novo tipo de primeiro encontro <span role="img">❤️</span>
              </p>
              <p className="wow move-up  mw-100 mw-md-75 mb--20">
                Skippit é um novo tipo de primeiro encontro que usa vídeo para
                mudar a maneira como as pessoas usam e experimentam os
                aplicativos de namoro
              </p>
              <p className="wow move-up  mw-100 mw-md-75 mb--20">
                Ligando para substituir os Ubers caros, os primeiros drinques
                estranhos e a pesca do gato que são comuns na maioria dos
                aplicativos de namoro.
              </p>
              <p className="wow move-up  mw-100 mw-md-75 mb--20">
                Skippit foi lançado nos EUA. Na primeira semana de lançamento
                atingiu o número 66 na App Store.
              </p>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 ">
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={onboardingImages[0]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={onboardingImages[1]} />
              </Col>
              <Col
                md={6}
                xs={12}
                style={{ padding: "0 10%", alignSelf: "center" }}
              >
                <div class="portfolio-h3">Onboarding</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  Ajude o usuário a facilitar a experiência do produto. Pode ser
                  tão simples quanto uma saudação e uma explicação ou tão
                  complexo quanto uma série de tarefas guiadas para os usuários
                  realizarem. Contanto que você forneça a seus usuários um
                  acesso aos benefícios de seu produto, você os está preparando
                  para o sucesso.
                </p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={onboardingImages[2]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={onboardingImages[3]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={onboardingImages[4]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={onboardingImages[5]} />
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={onboardingImages} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 justify-content-center d-none d-md-flex">
              <Col md={3} xs={6}>
                <Img fluid={onboardingImages[6]} />
              </Col>
              <Col md={3} xs={7}>
                <Img fluid={onboardingImages[7]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 justify-content-center text-center">
              <Col md={9} xs={12}>
                <div class="portfolio-h3 ">Navegação Principal</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  seu produto, você está configurando-os para o sucesso. Após
                  Onboarding, os usuários irão para a tela principal do Skippit,
                  onde podem usar um simples gesto de navegação para navegar
                  entre as telas. Deslizar para a esquerda irá para a tela
                  Partidas, deslizar para a direita para a tela Perfil e
                  deslizar para cima para a tela Descoberta.
                </p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={mainAppImages[0]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={mainAppImages[1]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={mainAppImages[2]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={mainAppImages[3]} />
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={mainAppImages} />
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-5 justify-content-center text-center">
              <Col md={9} xs={12}>
                <div class="portfolio-h3">
                  Datas de vídeo ao vivo - Happy Hour
                </div>
                <p class="portfolio-paragraph d-md-block d-none">
                  O Skippit usa vídeo para mudar a forma como as pessoas usam e
                  experimentam os aplicativos de namoro. Ao se cadastrar no
                  Happy Hour, o usuário terá uma lista das datas dos vídeos.
                </p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 justify-content-center d-none d-md-flex">
              <Col md={3} xs={6}>
                <Img fluid={liveVideoImages[0]} />
              </Col>
              <Col md={3} xs={7}>
                <Img fluid={liveVideoImages[1]} />
              </Col>
              <Col md={3} xs={7}>
                <Img fluid={liveVideoImages[2]} />
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={liveVideoImages} />
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[3]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[4]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[5]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[6]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 justify-content-center d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[7]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[8]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-5">
              <Col md={3} xs={12} style={{ alignSelf: "center" }}>
                <div class="portfolio-h3">Jogos / bate-papo</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  Por meio da tela Matches, os usuários podem acessar o chat
                  para enviar mensagens e fazer chamadas de vídeo.
                </p>
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={matchesImages[0]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={matchesImages[1]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={matchesImages[2]} />
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={matchesImages} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5">
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={profileImages[0]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={profileImages[1]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={profileImages[2]} />
              </Col>
              <Col md={3} xs={12} style={{ alignSelf: "center" }}>
                <div class="portfolio-h3">Perfil</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  Por meio da tela Perfil, os usuários podem editar
                  preferências, editar perfil e entrar em contato com o suporte.
                </p>
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={profileImages} />
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="skippit" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{
              name: "Sublime Comporta",
              link: "/work/sublimecomporta",
            }}
            prevProject={{
              name: "Wines From Another World",
              link: "/work/winesfromanotherworld",
            }}
            selectedLanguage="pt"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Skippit;

export const SkippitQuery = graphql`
  query {
    SkippitLiveVideoPt: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/live_video/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitMainNavigationPt: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/main_navigation/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitMatchesPt: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/matches/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitOnboardingPt: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/onboarding/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitProfilePt: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/profile/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitHeaderPt: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/header/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
